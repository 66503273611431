<script>
import Layout from '@/views/layouts/main_profile'
import i18n from '@/i18n'
import service from '@/server/http_service'
import geo_data from '@/assets/file/geo_data.JSON'
import Swal from 'sweetalert2'
import global_func from '@/global_function'
import simplebar from 'simplebar-vue'
export default {
  page: {
    title: i18n.t('settings.title'),
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('settings')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      active_tab: 0,
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      dataGoodsType_data2: [],
      dataGoodsType_ck: [],
      dataGoodsType_note: '',
      dataGoodsType_sel: false,
      route_raw_data: [],
      province_service_data: [],
      transporter_lang: true,
      geo_data: geo_data,
      // route
      data_prov_data: {},
      data_prov_sel: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
      prov_sel_all: '',
      goods_lang: true,
    }
  },
  watch: {},
  mounted() {
    this.getDataGoodsType()
    this.getCompanyDataById()
  },
  methods: {
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      // console.log(this.account_data)
      let account_data = this.account_data[0]
      let goodsTypeData = account_data.goods_type
      let goodsTypeDataObj = goodsTypeData.split(',')
      var that = this
      service.postService('getGoodsType').then((rp) => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let goodsTypeUserStatus = ''
          for (let index2 = 0; index2 < goodsTypeDataObj.length; index2++) {
            const element2 = goodsTypeDataObj[index2]
            if (element.goods_type_id == element2) {
              goodsTypeUserStatus = 'checked'
              this.dataGoodsType_note = account_data.goods_type_note
            }
          }
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: goodsTypeUserStatus,
          }
          this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
          if (this.account_lang != 'th') {
            this.goods_lang = false
          }
        }
        // console.log(this.dataGoodsType_data2)
        that.goods_type_sel()
      })
    },
    // eslint-disable-next-line no-unused-vars
    goods_type_sel(data) {
      // console.log(data)
      // console.log(this.dataGoodsType_data)
      this.data_goods_type_sel = ''
      this.data_goods_type_sel_full = ''
      var countTypeSelect = 0
      var temp_data_goods_type_sel = ''
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        const element = this.dataGoodsType_data[index]
        if (element.goods_type_status == 'checked') {
          if (this.data_goods_type_sel != '') {
            if (countTypeSelect < 2) {
              this.data_goods_type_sel += ', '
              this.data_goods_type_sel_full += ', '
              if (!this.goods_lang) {
                this.data_goods_type_sel += element.goods_type_name_en
              } else {
                this.data_goods_type_sel += element.goods_type_name_th
              }
              if (element.goods_type_name_th == 'อื่นๆ') {
                if (this.dataGoodsType_note != '') {
                  this.data_goods_type_sel += ' (' + this.dataGoodsType_note + ')'
                }
              }
              temp_data_goods_type_sel += this.data_goods_type_sel
            } else {
              this.data_goods_type_sel = temp_data_goods_type_sel + ', +' + (countTypeSelect - 1)
            }
          } else {
            if (!this.goods_lang) {
              this.data_goods_type_sel = element.goods_type_name_en
            } else {
              this.data_goods_type_sel = element.goods_type_name_th
            }
            if (element.goods_type_name_th == 'อื่นๆ') {
              if (this.dataGoodsType_note != '') {
                this.data_goods_type_sel += ' (' + this.dataGoodsType_note + ')'
              }
            }
          }
          if (!this.goods_lang) {
            this.data_goods_type_sel_full += element.goods_type_name_en
          } else {
            this.data_goods_type_sel_full += element.goods_type_name_th
          }
          if (element.goods_type_name_th == 'อื่นๆ') {
            if (this.dataGoodsType_note != '') {
              this.data_goods_type_sel_full += ' (' + this.dataGoodsType_note + ')'
            }
          }
          countTypeSelect++
        }
      }
    },
    ck_all_sel() {
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        if (this.dataGoodsType_sel == 'checked') {
          this.dataGoodsType_data[index].goods_type_status = 'checked'
        } else {
          this.dataGoodsType_data[index].goods_type_status = ''
        }
      }

      for (let index2 = 0; index2 < this.dataGoodsType_data2.length; index2++) {
        if (this.dataGoodsType_sel == 'checked') {
          this.dataGoodsType_data2[index2].goods_type_status = 'checked'
        } else {
          this.dataGoodsType_data2[index2].goods_type_status = ''
        }
      }
    },
    ck_sel() {
      var flag = 0
      for (let index = 0; index < this.dataGoodsType_data.length; index++) {
        const row = this.dataGoodsType_data[index]
        if (row.goods_type_status == '') {
          flag = 1
        }
      }

      for (let index2 = 0; index2 < this.dataGoodsType_data2.length; index2++) {
        const row = this.dataGoodsType_data2[index2]
        if (row.goods_type_status == '') {
          flag = 1
        }
      }

      if (flag != 0) {
        this.dataGoodsType_sel = false
      } else {
        this.dataGoodsType_sel = 'checked'
      }
    },
    getCompanyDataById() {
      // var that = this
      // console.log(this.account_data)
      let id_member = this.account_data[0].id_member
      service
        .postService('getCompanyDataById', {
          provider_id: id_member,
        })
        .then((rp) => {
          // console.log(rp)
          this.route_raw_data = []
          this.route_raw_data = rp.route_data
          this.checkDataProv(this.route_raw_data)
        })
    },
    checkDataProv(data) {
      var that = this
      this.province_service_data = data
      // console.log(this.province_service_data)
      this.splitProvServiceData()
      setTimeout(() => {
        that.getDataAddInfo()
      }, 300)
    },
    getDataAddInfo() {
      this.setService_route(geo_data.province, geo_data.geographies)
    },
    splitProvServiceData() {
      var data = this.province_service_data
      // console.log(data)
      var prov_sel_all = ''
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        var row_prov = element.id_provinces
        if (index > 0) {
          prov_sel_all += ','
        }
        prov_sel_all += row_prov
        // var split_prov = row_prov.split(',')
      }
      this.prov_sel_all = prov_sel_all
      // console.log(prov_sel_all)
    },
    setService_route(data, data2) {
      var prov = data
      // eslint-disable-next-line no-unused-vars
      var geo = data2
      var data_prov = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }
      // console.log(prov)
      for (let index = 0; index < prov.length; index++) {
        let rowdata = {
          id: prov[index].id,
          name_th: prov[index].name_th,
          name_en: prov[index].name_en,
          geography_id: prov[index].geography_id,
          status: this.checkProvStatus(prov[index].id),
        }
        const rowProv = rowdata
        if (rowProv.geography_id == '1') {
          data_prov['1'].push(rowProv)
        } else if (rowProv.geography_id == '2') {
          data_prov['2'].push(rowProv)
        } else if (rowProv.geography_id == '3') {
          data_prov['3'].push(rowProv)
        } else if (rowProv.geography_id == '4') {
          data_prov['4'].push(rowProv)
        } else if (rowProv.geography_id == '5') {
          data_prov['5'].push(rowProv)
        } else if (rowProv.geography_id == '6') {
          data_prov['6'].push(rowProv)
        }
      }
      // console.log(data_prov)
      this.data_prov_data = data_prov
      if (this.account_lang == 'th') {
        this.transporter_lang = true
      } else {
        this.transporter_lang = false
      }
      // this.splitProvServiceData()
    },
    checkProvStatus(id) {
      var prov_id = id
      var arr_prov = this.prov_sel_all.split(',')
      var flg = false
      var data_return = ''
      for (let index = 0; index < arr_prov.length; index++) {
        const prov_row_id = arr_prov[index]
        if (prov_row_id == prov_id) {
          flg = true
        }
      }
      if (flg) {
        data_return = 'checked'
      }
      return data_return
    },
    async sendDataSettings() {
      Swal.fire({
        title: 'ยืนยันการตั้งค่า',
        text: '',
        icon: 'warning',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่',
        allowOutsideClick: true,
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendDataSettingsService()
        } else if (result.isDenied) {
          return
        }
      })
    },
    sendDataSettingsService() {
      let account_data = this.account_data[0]
      service
        .postService('editSettingsUser', {
          account_data: account_data,
          dataGoodsType_data: this.dataGoodsType_data,
          dataGoodsType_note: this.dataGoodsType_note,
          data_prov_data: this.data_prov_data,
        })
        .then((rp) => {
          // console.log(rp)
          if (rp.data != undefined) {
            if (rp.data == 'ok') {
              global_func.alertMessage('บันทึกสำเร็จ', '', 'success')
            }
          }
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="container-fluid">
      <div style="padding: 40px">
        <b-row>
          <b-col lg="12">
            <h1>
              {{ lang.title }}
            </h1>
          </b-col>
          <b-col lg="12">
            <nav>
              <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
                <b-tab :active="active_tab == 0">
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block topnav-text">ประเภทสินค้า</span>
                  </template>
                </b-tab>
                <b-tab :active="active_tab == 1">
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block topnav-text">เส้นทางบริการ</span>
                  </template>
                </b-tab>
                <!-- <b-tab :active="active_tab == 2"  />
                <b-tab :active="active_tab == 3"  />
                <b-tab :active="active_tab == 4"  />
                <b-tab :active="active_tab == 5"  /> -->
              </b-tabs>
            </nav>
            <template v-if="active_tab == 0">
              <br />
              <!-- <h2>{{ $t('register_goods_owner.goods_type.topic') }}</h2>
              <br /> -->
              <b-row>
                <b-col>
                  <div v-for="(option, index) in dataGoodsType_data" :key="option.goods_type_id">
                    <b-form-checkbox v-if="option.goods_type_id == '1'" v-model="dataGoodsType_sel" value="checked" name="checkbox" class="check-col" @change="ck_all_sel()">
                      <template v-if="goods_lang">เลือกทั้งหมด</template>
                      <template v-else>Select All</template>
                    </b-form-checkbox>
                    <div v-else-if="option.goods_type_name_th == 'อื่นๆ'">
                      <b-row>
                        <b-col lg="2" md="2">
                          <b-form-checkbox v-model="dataGoodsType_data[index].goods_type_status" value="checked" name="checkbox" class="check-col" @change="ck_sel()">
                            <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                            <template v-else>{{ option.goods_type_name_en }}</template>
                          </b-form-checkbox>
                        </b-col>
                        <b-col lg="6" md="6">
                          <b-form-input id="goods_type_other" v-model="dataGoodsType_note" type="text" class="form-control" name="goods_type_other" :style="{ display: dataGoodsType_data[index].goods_type_status == 'checked' ? 'block' : 'none' }" />
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-checkbox v-else v-model="dataGoodsType_data[index].goods_type_status" value="checked" name="checkbox" class="check-col" @change="ck_sel()">
                      <template v-if="goods_lang">{{ option.goods_type_name_th }}</template>
                      <template v-else>{{ option.goods_type_name_en }}</template>
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col />
              </b-row>
              <br />
              <hr />
            </template>
            <template v-if="active_tab == 1">
              <simplebar style="max-height: 62.5vh; min-height: 62.5vh;">
                <br />
                <div>
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2412@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <p class="font-color-blue">ภาคกลาง</p>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[2]" :key="option.id" md="3">
                          <template v-if="data_prov_data[2][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2417@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <p class="font-color-blue">ภาคเหนือ</p>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[1]" :key="option.id" md="3">
                          <template v-if="data_prov_data[1][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2414@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <p class="font-color-blue">ภาคใต้</p>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[6]" :key="option.id" md="3">
                          <template v-if="data_prov_data[6][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2419@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <span class="font-color-blue">ภาคอีสาน</span>
                        <span class="font-color-blue" style="font-size: 16px">(ตะวันออกเฉียงเหนือ)</span>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[3]" :key="option.id" md="3">
                          <template v-if="data_prov_data[3][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2422@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <p class="font-color-blue">ภาคตะวันออก</p>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[5]" :key="option.id" md="3">
                          <template v-if="data_prov_data[5][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                  <b-row>
                    <b-col lg="2">
                      <b-row class="d-flex justify-content-center">
                        <img src="@/assets/images/thailand pic/Group 2413@2x.png" height="140px" class="text-center" />
                      </b-row>
                      <b-row class="d-flex justify-content-center text-service-route">
                        <br />
                        <p class="font-color-blue">ภาคตะวันตก</p>
                      </b-row>
                    </b-col>
                    <b-col lg="10">
                      <b-row>
                        <b-col v-for="(option, index) in data_prov_data[4]" :key="option.id" md="3">
                          <template v-if="data_prov_data[4][index].status == 'checked'">
                            <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                          <template v-else>
                            <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col-custom cursor-default">
                              <template v-if="transporter_lang">{{ option.name_th }}</template>
                              <template v-else>{{ option.name_en }}</template>
                            </b-form-checkbox>
                          </template>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <br />
                  <hr />
                  <br />
                </div>
              </simplebar>
            </template>
            <br />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="form-group d-flex justify-content-center align-items-center big_btn_green" @click="sendDataSettings()">
              <span class="font-size-16">
                {{ lang.btn.save }}
              </span>
            </div>
          </b-col>
          <b-col />
          <b-col />
          <b-col />
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<style>
.check-col {
  margin-bottom: 1rem;
}
.check-col-custom {
  margin-bottom: 0.5rem;
}
</style>
